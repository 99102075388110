<template>
  <div class="container">
    <div class="content">
      <div class="live-wrap" v-if="state.liveList.length">
        <div
          class="live-list"
          :style="backgroundStyle(item)"
          v-for="item in state.liveList"
          :key="item.id"
          @click="handleVideoPlay(item)"
        >
          <div class="lives">
            <span class="device-desc">{{ item.deviceDesc }}</span>
          </div>
        </div>
      </div>
      <EmptyDataCard
        style="margin-top: 44px"
        v-else
        text="暂无学校实况!"
        mode="list"
      ></EmptyDataCard>
    </div>
  </div>
</template>

<script>
import { querySyDeviceSelectPage } from "@/api/user";
import ZiXiShi from "@/assets/zhibo/zixishi.png";
import JieSuanTai from "@/assets/zhibo/jiesuantai.png";
import HouChu from "@/assets/zhibo/houchu.png";
import CanTing from "@/assets/zhibo/canting.png";
import Default from "@/assets/zhibo/default.jpg";
import Chufangbatai from "@/assets/zhibo/chufangbatai.png";
import { defineComponent, onMounted, reactive, ref } from "vue";
import EmptyDataCard from "@/components/EmptyDataCard.vue";
import { useRoute, useRouter } from "vue-router";
const BackImageConfig = {
  M014002482401000125: CanTing, // 餐厅后面
  M014002482401000064: JieSuanTai, // 餐台结算区
  M014002482401000091: CanTing, // 餐厅电箱旁
  M014002482401000082: ZiXiShi, // 自习室
  M014002482401000190: HouChu, // 后厨
  M014002482401000077: Chufangbatai, // 厨房吧台
};

export default defineComponent({
  name: "GaoBuMonitor",

  components: {
    EmptyDataCard,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();

    const state = reactive({
      createOrg: null,
      liveList: [],
      params: {
        page: { pageIndex: 1, pageSize: 100 },
      },
    });

    onMounted(() => {
      if (route?.query?.token) {
        localStorage.setItem("token", route?.query?.token);
      }
      state.createOrg = route?.query?.creatOrg;
      getLiveList();
    });

    function backgroundStyle(item) {
      return {
        backgroundImage: `url(${item.imgUrl ? item.imgUrl : item.bgURL})`,
        backgroundSize: "cover", // 根据需要设置
      };
    }

    function getLiveList() {
      querySyDeviceSelectPage({
        ...state.params,
        deviceType: 1,
        createOrg: state.createOrg,
      }).then((res) => {
        if (res.code === "00") {
          const list = res.data?.list ?? [];
          state.liveList = list.map((item) => {
            return {
              ...item,
              bgURL: BackImageConfig[item.serialNo]
                ? BackImageConfig[item.serialNo]
                : Default,
            };
          });
        } else {
          state.liveList = [];
        }
      });
    }
    function handleVideoPlay(item) {
      console.log("item:", item);
      router.push({
        path: '/monitorDetail',
        query: {
          serialNo: item.serialNo,
          creatOrg: route?.query?.creatOrg,
          title: item.deviceDesc
        }
      })
    }

    return {
      state,
      handleVideoPlay,
      backgroundStyle,
    };
  },
});
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  width: 100%;
  height: 100%;
}

.content {
  width: 100%;
  height: calc(100% - 140px);
  background-color: #e9f1f8;
  border-radius: 20px 20px 0 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 12px;
}
.live-wrap {
  overflow-y: auto;
  .live-list {
    height: 184px;
    width: calc(100vw - 22px);
    margin-top: 19px;
    .lives {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 18px;
      color: #333;
      font-weight: 500;
      .add {
        color: #013152;
      }
      .cle {
        color: #999;
      }

      .device-desc {
        background-color: #17435e;
        font-family: PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #fffefe;
        padding: 4px 10px;
      }

      .device-time {
        font-family: PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #000000;
        padding: 4px 10px;
      }
    }
    .time {
      color: #999;
      padding: 0 30px;
      font-size: 12px;
    }
  }
}
</style>
