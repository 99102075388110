import axios from 'axios'
// import { Notify } from 'vant'
import { showConfirmDialog } from "vant";
import { useRouter } from "vue-router";

const router = useRouter();
// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  timeout: 30000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    console.log('config>>>>',config)
    const token = localStorage.getItem('token')
    if (token) {
      config.headers['token'] = token
    }


    if(config.url === '/syUser/loginEmp' || config.url === '/syUser/logInSendCode') {
      delete config.headers['token']
    }
    return config
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)

service.interceptors.response.use(

  response => {
    const res = response.data
    console.log(res)
    if (res.code !== '00') {
      // Notify({
      //   message: res.message || 'Error',
      //   type: 'warning',
      //   duration: 5 * 1000
      // })
      
      if(res.code === '02' || res.code === '04') {
         localStorage.clear();
         isLoginDialog()
      }
      return res
    } else {
      if (response.config.url.endsWith('/syUser/loginEmp')) {
        localStorage.setItem('token', response.headers.token)
      }
      return res
    }
  },
  error => {
    console.log('err' + error) // for debug
    // Notify({
    //   message: error.message,
    //   type: 'danger',
    //   duration: 5 * 1000
    // })
    return Promise.reject(error)
  }
)

const handleGoLogin = () => {
  router.push({
    path: "/login",
  });
};


const isLoginDialog = () => {
  showConfirmDialog({
    title: "温馨提示",
    message: "当前操作需要登录，请前往登录。",
  })
    .then(() => {
      // on confirm
      console.log("确认登录");
      handleGoLogin();
    })
    .catch(() => {
      // on cancel
      console.log("取消登录");
    });
};

export default service
