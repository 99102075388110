<template>
  <view class="container">
   <video autoplay id="videoElement" controls></video>


   <u-empty style="padding-top: 300px;" v-if="state.emptylive" text="远程连接失败" mode="data">
			<u-button @click="handleUpdate" size="small" type="primary" :style="{marginTop:10+'px'}" text="点击重新获取">
			</u-button>
		</u-empty>
  </view>
</template>

<script>
import {
  queryMediaVideoSubscribeStreame,
  queryMediaVideoUnSubscribeStream,
} from "@/api/user";
import { defineComponent, onBeforeUnmount, onMounted, reactive, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import Hls from 'hls.js';

export default defineComponent({
  name: "GaoBuMonitorDetail",

  components: {
  },

  setup() {
    const route = useRoute();
    const router = useRouter();

    const state = reactive({
      liveUrl: "",
      serialNo: "",
      title: "学校实况",
      emptylive: false
    });

    const player = ref(null)

    onMounted(() => {
      state.serialNo = route?.query?.serialNo;
      state.title = route?.query?.title ?? "学校实况";
      liveMediaVideoSubscribeStream();
    });
    
    onBeforeUnmount(() => {
      liveMediaVideoUnSubscribeStream()
    })

    const handleUpdate = () => {
      liveMediaVideoSubscribeStream();
    }

    const liveMediaVideoUnSubscribeStream = () => {
      queryMediaVideoUnSubscribeStream({
        snNum: state.serialNo,
      }).then((res) => {
        console.log("取消订阅======", res);
      });
    };
    const liveMediaVideoSubscribeStream = () => {

      state.emptylive = false

      queryMediaVideoSubscribeStreame({
        snNum: state.serialNo,
      }).then((res) => {
        console.log("订阅======", res);
        if (res.code === "00") {
          state.liveUrl = res?.data?.streamUrl;
          playLive();
          setTimeout(() => {
            playLive();
          }, 2000);
          
        } else {
          state.liveUrl = "";
          state.emptylive = true
        }
      }).catch(() => {
        state.emptylive = true
      }).finally(() => {
        
      });
    };

    const playLive = () => {
      const video = document.getElementById('videoElement');
      console.log('=====',video)
      const splitRul = state.liveUrl.split('/');
      if (Hls.isSupported()) {
        console.log('================================Hls')
        const hls = new Hls();
        hls.loadSource(`https://www.ubetterplus.com/hls/${splitRul[splitRul.length - 1]}.m3u8`); // 需要转换为HLS或其他hls.js支持的格式，例如使用nginx-rtmp-module转码为HLS或DASH。
        hls.attachMedia(video);
        hls.on(Hls.Events.MANIFEST_PARSED, function() {
          video.play()
        });
      } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
        console.log('================================video')
        video.src = `https://www.ubetterplus.com/hls/${splitRul[splitRul.length - 1]}.m3u8`; // 直接设置为RTMP URL，浏览器原生支持。
        video.addEventListener('loadedmetadata', function() {
          console.log('加载完成')
          video.play();
        });
      }
    };

    return {
      state,
      handleUpdate
    };
  },
});
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  width: 100%;
  height: 100%;
}

#videoElement {
  width: 100%;
}
</style>