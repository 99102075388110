<template>
  <div class="page-container">
    <div class="content-item">
      <div class="item-header-info">
        <div class="item-header-info-name">学生</div>
        <div class="item-header-info-status-value">{{state.signInInfo.sudentInfo.userName}}</div>
      </div>

      <div class="item-header-info">
        <div class="item-header-info-name">签约周期</div>
        <div class="item-header-info-status-value">{{getDay(state.signInInfo.sudentInfo.checkInDate)}} ~ {{getDay(state.signInInfo.sudentInfo.expireDate)}}</div>
      </div>

      <div class="item-header-info">
        <div class="item-header-info-name">已签到</div>
        <div class="item-header-info-status-value">{{state.signInInfo.sudentInfo.record}}天</div>
      </div>
      <div class="item-header-info">
        <div class="item-header-info-name">上午段<span style="margin-left: 4px" class="van-calendar-day-1"></span></div>
        <div class="item-header-info-status-value">{{state.signInInfo.sudentInfo.morrecord}}次</div>
      </div>
      <div class="item-header-info">
        <div class="item-header-info-name">中午段<span style="margin-left: 4px" class="van-calendar-day-2"></span></div>
        <div class="item-header-info-status-value">{{state.signInInfo.sudentInfo.lunrecord}}次</div>
      </div>
      <div class="item-header-info">
        <div class="item-header-info-name">晚上段<span style="margin-left: 4px" class="van-calendar-day-3"></span></div>
        <div class="item-header-info-status-value">{{state.signInInfo.sudentInfo.dinrecord}}次</div>
      </div>
    </div>

    <div class="date-picker">
      <van-calendar
        :show-title="false"
        :show-confirm="false"
        :first-day-of-week="1"
        :min-date="state.minDate"
        :max-date="state.maxDate"
        @select="onSelect"
        :poppable="false"
        switch-mode="month"
        :default-date="state.value"
        :formatter="formatter"
      >
      <div>自定义内容</div>
      </van-calendar>
    </div>

    <div v-if="state.signInInfo.currentSignInfo" class="content-item">
      <div class="item-header-info">
        <div class="item-header-info-status-value">上午段签到状态</div>
        <div class="item-header-info-status-value">{{getRangInfo(1) == 1 ? '已签到' : '未签到'}}</div>
      </div>
      <div class="item-header-info">
        <div class="item-header-info-status-value">中午段签到状态</div>
        <div class="item-header-info-status-value">{{getRangInfo(2) == 1 ? '已签到' : '未签到'}}</div>
      </div>
      <div class="item-header-info">
        <div class="item-header-info-status-value">晚上段签到状态</div>
        <div class="item-header-info-status-value">{{getRangInfo(3) == 1 ? '已签到' : '未签到'}}</div>
      </div>

      <div class="item-header-info">
        <div class="item-header-info-status-value">签约签到时间</div>
        <div class="item-header-info-status-value">{{getDay(state.signInInfo.currentSignInfo.list[0].performanceTime)}}</div>
      </div>

      <div class="item-header-info">
        <div class="item-header-info-status-value">是否人工修正</div>
        <div class="item-header-info-status-value">{{state.signInInfo.currentSignInfo.list[0].isUpdate ? "是" : "否"}}</div>
      </div>

      <div class="item-header-info">
        <div class="item-header-info-status-value">修正时间</div>
        <div class="item-header-info-status-value">{{state.signInInfo.currentSignInfo.list[0].updateTime ?? '--'}}</div>
      </div>
      <div class="item-header-info">
        <div class="item-header-info-status-value">修正人</div>
        <div class="item-header-info-status-value">{{state.signInInfo.currentSignInfo.updateNameStr ?? '--'}}</div>
      </div>
      <div class="item-header-info">
        <div class="item-header-info-status-value">修正原因</div>
        <div class="item-header-info-status-value">
          {{state.signInInfo.currentSignInfo.list[0].updateReason ?? '--'}}
        </div>
      </div>
    </div>

    <div style="height: 128px"></div>

    <div class="item-footer">
      <van-button
        size="normal"
        style="width: 140px;font-size: 16px"
        plain
        hairline
        type="primary"
        @click="handleGoTravelRecords"
        >查看出行情况</van-button
      >
      <van-button
        size="normal"
        style="width: 140px;font-size: 16px"
        plain
        type="success"
        @click="handleChangeRecords"
        >修改</van-button
      >
    </div>

    <van-popup
      position="bottom"
      round
      v-model:show="isPopUpShow"
      :style="{ padding: '12px 0px' }"
    >
      <van-form @submit="onSubmit">
        <van-cell-group inset style="margin-top: 12px">
          <van-field
            v-model="state.signInInfo.form.statusMorningText"
            name="上午段签到状态"
            label="上午段签到状态"
            placeholder="请选择上午段签到状态"
            is-link
            @click="showPicker = true;state.currentTimePeriod=1"
            :rules="[{ required: true, message: '请选择上午段签到状态' }]"
          />
          <van-field
            v-model="state.signInInfo.form.statusNoonText"
            name="中午段签到状态"
            label="中午段签到状态"
            placeholder="请选择中午段签到状态"
            is-link
            @click="showPicker = true;state.currentTimePeriod=2"
            :rules="[{ required: true, message: '请选择中午段签到状态' }]"
          />
          <van-field
            v-model="state.signInInfo.form.statusNightText"
            name="晚上段签到状态"
            label="晚上段签到状态"
            placeholder="请选择晚上段签到状态"
            is-link
            @click="showPicker = true;state.currentTimePeriod=3"
            :rules="[{ required: true, message: '请选择晚上段签到状态' }]"
          />
          <!-- <van-field
            v-model="state.signInInfo.form.timePeriodText"
            name="签到分段"
            label="签到分段"
            placeholder="请选择签到分段"
            is-link
            @click="showTimePicker = true"
            :rules="[{ required: true, message: '请选择签到分段' }]"
          /> -->
          <van-field
            v-model="state.signInInfo.form.updateReason"
            type="textarea"
            name="修正原因"
            label="修正原因"
            rows="2"
            autosize
            placeholder="修正原因"
            maxlength="50"
            show-word-limit
            :rules="[{ required: true, message: '请填写修正原因' }]"
          />
        </van-cell-group>
        <div style="margin: 16px">
          <van-button round block type="primary" native-type="submit">
            提交
          </van-button>
        </div>
      </van-form>
    </van-popup>

    <van-popup v-model:show="showPicker" round position="bottom">
      <van-picker
        :columns="columns"
        @cancel="showPicker = false;currentTimePeriod=null"
        @confirm="onConfirm"
      />
    </van-popup>

    <van-popup v-model:show="showTimePicker" round position="bottom">
      <van-picker
        :columns="timeColumns"
        @cancel="showTimePicker = false"
        @confirm="onTimeConfirm"
      />
    </van-popup>
    
  </div>
</template>

<script>
import dayjs from "dayjs";
import { defineComponent, onMounted, reactive, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { querySyPerformanceDetail, querySyPerformanceUpdate, querySyPerformanceAdd } from '@/api/user'
import { showNotify } from 'vant';
import { h } from 'vue'

export default defineComponent({
  name: "SignIn",

  components: {},

  setup() {
    const route = useRoute();

    const router = useRouter();

    const dateToday = dayjs();
    const dateExpire = dayjs(route.query.expireDate);
    const diff = dateToday.diff(dateExpire);
    let _maxDate = null
    if (diff < 0) {
        _maxDate = dateToday
    } else {
        _maxDate = dateExpire
    }


    const state = reactive({
      minDate: new Date(dayjs(route.query.checkInDate).format("YYYY-MM-DD")),
      maxDate: new Date(_maxDate),

      value:null,
      
      signInInfo: {
        rangeList: [],
        currentSignInfo: null,
        currentSelectDate: null,
        sudentInfo: {
            userId: route?.query?.userId,
            userName: route?.query?.userName,
            checkInDate: dayjs(route.query.checkInDate).format("YYYY-MM-DD"),
            expireDate: dayjs(route.query.expireDate).format("YYYY-MM-DD"),
            record: 0,
            performanceId: route?.query?.performanceId,
            morrecord: null,
            lunrecord: null,
            dinrecord: null
        },
        form: {
            status: null,
            updateReason: null,
            statusText: null,
            timePeriodText: null,
            statusNoonText: null,
            statusMorningText: null,
            statusNightText: null,
            statusNoon: null,
            statusMorning: null,
            statusNight: null
        },
        currentTimePeriod: null,
      }
    });

    const columns = [
      { text: "已签", value: 1 },
      { text: "未签", value: 0 },
    ];

    const timeColumns = [
      { text: "上午段", value: 1 },
      { text: "中午段", value: 2 },
      { text: "晚上段", value: 3 },
    ]

    const isPopUpShow = ref(false);

    const showPicker = ref(false);

    const showTimePicker = ref(false);

    const handleGoTravelRecords = () => {
      router.push({
        path: "/travelRecords",
        query: {
          userId: route?.query?.userId,
        },
      });
    };

    const handleChangeRecords = () => {
        if(!state.signInInfo.currentSelectDate) {
            showNotify({ type: 'warning', message: '点击选择日期', duration: 2000 })
            return
        }
        isPopUpShow.value = true
    }

    const onConfirm = ({ selectedOptions }) => {
      showPicker.value = false;
      console.log(selectedOptions);
      if(state.currentTimePeriod == 1) {
        state.signInInfo.form.statusMorningText = selectedOptions[0].text;
        state.signInInfo.form.statusMorning = selectedOptions[0].value;
      } else if(state.currentTimePeriod == 2) {
        state.signInInfo.form.statusNoonText = selectedOptions[0].text;
        state.signInInfo.form.statusNoon = selectedOptions[0].value;
      } else if(state.currentTimePeriod == 3) {
        state.signInInfo.form.statusNightText = selectedOptions[0].text;
        state.signInInfo.form.statusNight = selectedOptions[0].value;
      } 
      
    };

     const onTimeConfirm = ({ selectedOptions }) => {
      showTimePicker.value = false;
      console.log(selectedOptions);
      state.signInInfo.form.timePeriodText = selectedOptions[0].text;
      state.signInInfo.form.timePeriod = selectedOptions[0].value;
    };

    const onSubmit = async () => {
      
      console.log(state.signInInfo.form);
      console.log(state.signInInfo.currentSignInfo)

      const list = state.signInInfo.currentSignInfo?.list ?? []

      const find = state.signInInfo.currentSignInfo?.list?.find(item => item.timePeriod == state.signInInfo.form.timePeriod)

      try {
            let result = null
            if (list.length) {
              // result = await querySyPerformanceUpdate({
              //   performanceId: find.performanceId,
              //   id: find.id,
              //   status: state.signInInfo.form.status,
              //   updateReason: state.signInInfo.form.updateReason,
              //   timePeriod: state.signInInfo.form.timePeriod,
              //   isUpdate: true,
              //   performanceTime: dayjs(state.signInInfo.currentSelectDate).format(
              //     "YYYY-MM-DD HH:mm:ss"
              //   ),
              //   userId: find.userId,
              //   userName: find.userName,
              //   startTime: find.startTime,
              //   endTime: find.endTime,

              // });


              const isMorrecord = list.find(item => item.timePeriod == 1);
              const isLunrecord = list.find(item => item.timePeriod == 2);
              const isDinrecord = list.find(item => item.timePeriod == 3);
              const infoList = [];
              if(isMorrecord) {
                infoList.push(
                  {
                      id: isMorrecord.id,
                      isUpdate: true,
                      updateReason: state.signInInfo.form.updateReason,
                      status: state.signInInfo.form.statusMorning,
                      performanceId: route?.query?.performanceId,
                      performanceTime: dayjs(state.signInInfo.currentSelectDate).format("YYYY-MM-DD") + ' 08:00:00',
                      userId: route?.query?.userId,
                      userName: route?.query?.userName,
                      timePeriod: 1
                    }
                )
              } else {
                infoList.push(
                  {
                      isUpdate: true,
                      updateReason: state.signInInfo.form.updateReason,
                      status: state.signInInfo.form.statusMorning,
                      performanceId: route?.query?.performanceId,
                      performanceTime: this.momentFun(this.signInInfo.currentDate).format("YYYY-MM-DD") + ' 08:00:00',
                      userId: route?.query?.userId,
                      userName: route?.query?.userName,
                      timePeriod: 1
                    }
                )
              }

              if(isLunrecord) {
                infoList.push(
                  {
                      id: isLunrecord.id,
                      isUpdate: true,
                      updateReason: state.signInInfo.form.updateReason,
                      status: state.signInInfo.form.statusNoon,
                      performanceId: route?.query?.performanceId,
                      performanceTime: dayjs(state.signInInfo.currentSelectDate).format("YYYY-MM-DD") + ' 12:00:00',
                      userId: route?.query?.userId,
                      userName: route?.query?.userName,
                      timePeriod: 2
                    }
                )
              } else {
                infoList.push(
                  {
                      isUpdate: true,
                      updateReason: state.signInInfo.form.updateReason,
                      status: state.signInInfo.form.statusNoon,
                      performanceId: route?.query?.performanceId,
                      performanceTime: dayjs(state.signInInfo.currentSelectDate).format("YYYY-MM-DD") + ' 12:00:00',
                      userId: route?.query?.userId,
                      userName: route?.query?.userName,
                      timePeriod: 2
                    }
                )
              }

              if(isDinrecord) {
                infoList.push(
                  {
                      id: isDinrecord.id,
                      isUpdate: true,
                      updateReason: state.signInInfo.form.updateReason,
                      status: state.signInInfo.form.statusNight,
                      performanceId: route?.query?.performanceId,
                      performanceTime: dayjs(state.signInInfo.currentSelectDate).format("YYYY-MM-DD") + ' 19:00:00',
                      userId: route?.query?.userId,
                      userName: route?.query?.userName,
                      timePeriod: 3
                    }
                )
              } else {
                infoList.push(
                  {
                      isUpdate: true,
                      updateReason: state.signInInfo.form.updateReason,
                      status: state.signInInfo.form.statusNight,
                      performanceId: route?.query?.performanceId,
                      performanceTime: dayjs(state.signInInfo.currentSelectDate).format("YYYY-MM-DD") + ' 19:00:00',
                      userId: route?.query?.userId,
                      userName: route?.query?.userName,
                      timePeriod: 3
                    }
                )
              }

              

              result = await querySyPerformanceUpdate(infoList);
              
            } else {
            //   result = await querySyPerformanceAdd({
            //     performanceId: route.query.performanceId,
            //     status: state.signInInfo.form.status,
            //     updateReason: state.signInInfo.form.updateReason,
            //     isUpdate: true,
            //     performanceTime: dayjs(state.signInInfo.currentSelectDate).format(
            //       "YYYY-MM-DD HH:mm:ss"
            //     ),
            //     userId: state.signInInfo.sudentInfo.userId,
            //     userName: state.signInInfo.sudentInfo.userName,
            //     startTime: state.signInInfo.sudentInfo.checkInDate,
            //     endTime: state.signInInfo.sudentInfo.expireDate,
            //   });
            // }

            result = await querySyPerformanceAdd([
                  {
                    isUpdate: true,
                    updateReason: state.signInInfo.form.updateReason,
                    status: state.signInInfo.form.statusMorning,
                    performanceId: route?.query?.performanceId,
                    performanceTime: dayjs(state.signInInfo.currentSelectDate).format("YYYY-MM-DD") + ' 08:00:00',
                    userId: route?.query?.userId,
                    userName: route?.query?.userName,
                    timePeriod: 1
                  },
                  {
                    isUpdate: true,
                    updateReason: state.signInInfo.form.updateReason,
                    status: state.signInInfo.form.statusNoon,
                    performanceId: route?.query?.performanceId,
                    performanceTime: dayjs(state.signInInfo.currentSelectDate).format("YYYY-MM-DD") + ' 12:00:00',
                    userId: route?.query?.userId,
                    userName: route?.query?.userName,
                    timePeriod: 2
                  },
                  {
                    isUpdate: true,
                    updateReason: state.signInInfo.form.updateReason,
                    status: state.signInInfo.form.statusNight,
                    performanceId: route?.query?.performanceId,
                    performanceTime: dayjs(state.signInInfo.currentSelectDate).format("YYYY-MM-DD") + ' 19:00:00',
                    userId: route?.query?.userId,
                    userName: route?.query?.userName,
                    timePeriod: 3
                  }
                ]);
            }


            if (result.code == "00") {
                isPopUpShow.value = false;
                
                showNotify({ type: 'success', message: '签到修改成功', duration: 2000 })
                querySyPerformanceDetailData();
              } else {
                 showNotify({ type: 'warning', message: result.message, duration: 2000 })
              }
          } catch (error) {
            console.log(error)
          }
    };

    onMounted(() => {
        querySyPerformanceDetailData()
    });

    const querySyPerformanceDetailData = async () => { 
        try {
            const result = await querySyPerformanceDetail({ 
                userId: route?.query?.userId,
                businessUserId: route?.query?.businessUserId
            })
            if(result.code === '00') {
                state.signInInfo.rangeList = result?.data ?? []
                state.signInInfo.sudentInfo.record = result?.data?.[0]?.record ?? 0

                state.signInInfo.sudentInfo.morrecord = result?.data?.[0]?.morrecord ?? 0
                state.signInInfo.sudentInfo.lunrecord = result?.data?.[0]?.lunrecord ?? 0
                state.signInInfo.sudentInfo.dinrecord = result?.data?.[0]?.dinrecord ?? 0

                 state.signInInfo.currentSignInfo = state.signInInfo.rangeList.filter(
                  (item) =>
                    dayjs(item.performanceTime).format("YYYY-MM-DD") ===
                    dayjs(state.signInInfo.currentSelectDate).format("YYYY-MM-DD")
                )[0];

                // if (state.signInInfo.currentSignInfo) {
                //     state.signInInfo.currentSignInfo = state.signInInfo.rangeList.filter(
                //         (item) =>
                //             dayjs(item.performanceTime).format("YYYY-MM-DD") ===
                //             dayjs(state.signInInfo.currentSignInfo.performanceTime).format("YYYY-MM-DD")
                //     )[0];
                // } else {
                //     state.signInInfo.form.status = null;
                //     state.signInInfo.form.updateReason = null;
                // }
            } else {
                state.signInInfo.rangeList = [];
                state.signInInfo.sudentInfo.record = 0
            }
        } catch (error) {
            console.log(error)
            state.signInInfo.rangeList = [];
            state.signInInfo.sudentInfo.record = 0
        }
        
    }

    const onSelect = (options) => {
        state.signInInfo.form.status = null;
        state.signInInfo.form.updateReason = null;
        state.signInInfo.form.statusText = null;
        state.signInInfo.currentSelectDate = dayjs(options).format("YYYY-MM-DD");
        console.log('optons----',options)
        state.signInInfo.currentSignInfo = state.signInInfo.rangeList.filter(
        (item) =>
          dayjs(item.performanceTime).format("YYYY-MM-DD") ===
          dayjs(options).format("YYYY-MM-DD")
      )[0];

      console.log('state.signInInfo.currentSignInfo>>>>>',state.signInInfo.currentSignInfo)

       if (state.signInInfo.currentSignInfo) {
        state.signInInfo.form.updateReason =
        state.signInInfo.currentSignInfo?.list?.[0].updateReason;
        state.signInInfo.form.statusMorning = 0;
        state.signInInfo.form.statusMorningText = '未签';

        state.signInInfo.form.statusNoon = 0;
        state.signInInfo.form.statusNoonText = '未签';

        state.signInInfo.form.statusNight = 0;
        state.signInInfo.form.statusNightText = '未签';
      } else {
        state.signInInfo.form.updateReason = null;
        state.signInInfo.form.statusMorning = null;
        state.signInInfo.form.statusMorningText = null;
        state.signInInfo.form.statusNight = null;
        state.signInInfo.form.statusNightText = null;
        state.signInInfo.form.statusNoon = null;
        state.signInInfo.form.statusNoonText = null;
      }

      state.signInInfo.currentSignInfo?.list.forEach(el => {
        if(el.timePeriod == 1) {
          state.signInInfo.form.statusMorning = el.status;
          state.signInInfo.form.statusMorningText = el.status == 1 ? '已签' : '未签';
        }

        if(el.timePeriod == 2) {
          state.signInInfo.form.statusNoon = el.status;
          state.signInInfo.form.statusNoonText = el.status == 1 ? '已签' : '未签';
        }

        if(el.timePeriod == 3) {
          state.signInInfo.form.statusNight = el.status;
          state.signInInfo.form.statusNightText = el.status == 1 ? '已签' : '未签';
        }
        
      })

    }

    const formatter = (day) => {

      const rangeList = state.signInInfo.rangeList.map((item) =>
        dayjs(item?.performanceTime).format("YYYY-MM-DD")
      );
      if (!rangeList.length) {
        day.bottomInfo = ''
        day.className = ''
      };

      rangeList.forEach(i => {
        if (rangeList.includes(dayjs(day.date).format("YYYY-MM-DD"))) {
        const info = state.signInInfo.rangeList.find(
          (item) =>
            dayjs(item.performanceTime).format("YYYY-MM-DD") ===
            dayjs(day.date).format("YYYY-MM-DD")
        );

        const isMorrecord = info.list.find(item => item.timePeriod == 1);
        const isLunrecord = info.list.find(item => item.timePeriod == 2);
        const isDinrecord = info.list.find(item => item.timePeriod == 3);

        const spanChild = [];

        if(isMorrecord && isMorrecord.status == 1) {
          spanChild.push(h('span', {class: 'van-calendar-day-1'}))
        }

        if(isLunrecord && isLunrecord.status == 1) {
          spanChild.push(h('span', {class: 'van-calendar-day-2'}))
        }

        if(isDinrecord && isDinrecord.status == 1) {
          spanChild.push(h('span', {class: 'van-calendar-day-3'}))
        }

        day.bottomInfo = h('div', spanChild)
      } else {
        day.bottomInfo = ''
      }
      
      })
return day;

      
    }

    const getDay = (day) => {
        return dayjs(day).format('YYYY-MM-DD')
    }

    const getRangInfo = (type) => {
      const info = state.signInInfo.currentSignInfo.list.find(item => item.timePeriod == type);
      return info?.status ?? null
    }

    return {
      state,
      handleGoTravelRecords,
      isPopUpShow,
      showPicker,
      columns,
      onConfirm,
      onSubmit,
      formatter,
      onSelect,
      handleChangeRecords,
      getDay,
      showTimePicker,
      timeColumns,
      onTimeConfirm,
      getRangInfo
    };
  },
});
</script>

<style lang="scss" scoped>
.page-container {
  background: #e9f1f8;
  height: 100vh;
  width: 100%;
  overflow-y: scroll;
}

.content-item {
  background: #fff;
  margin: 12px 8px;
  border-radius: 4px;
  padding: 12px;
  text-align: left;
}

.item-header-info {
  display: flex;
  justify-content: space-between;
  padding: 4px 0;
  align-items: center;

  .item-des {
    color: #333333;
    font-size: 14px;
    vertical-align: middle;
  }

  .item-header-info-name {
    font-family: PingFang SC;
    font-weight: 400;
    font-size: 18px;
    color: #000000;
    font-weight: 600;
    display: flex;
    align-items: center;

    span {
      font-size: 16px;
      font-weight: 500;
      color: burlywood;
    }
  }

  .item-header-info-status {
    color: #fff;
    font-size: 14px;
    background: #113050;
    padding: 4px 12px;
    border-radius: 24px;
  }

  &-text {
    color: #113050;
    font-size: 12px;
    padding: 4px 12px;
  }

  &-value {
    font-size: 12px;
    padding: 4px 12px;
  }

  .item-header-info-other {
    font-family: PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #666666;
    display: flex;
    align-items: center;

    p {
      color: #ff3638;
    }
  }
}

.item-footer {
  position: fixed;
  bottom: 0px;
  display: flex;
  justify-content: space-around;
  width: 100%;
  background: #fff;
  padding: 22px 0;
  border-top: 10px solid #e9f1f8;
}

:deep(.van-calendar-day-1){
  color: #52c41a;
  display: inline-block;
        width: 6px; /* 小圆点直径 */
        height: 6px; /* 小圆点直径 */
        background-color: #52c41a; /* 小圆点颜色 */
        border-radius: 50%; /* 圆形 */
        margin-right: 5px; /* 与文本的间隔 */
}


:deep(.van-calendar-day-2) {
  color: #faad14;
  display: inline-block;
        width: 6px; /* 小圆点直径 */
        height: 6px; /* 小圆点直径 */
        background-color: #faad14; /* 小圆点颜色 */
        border-radius: 50%; /* 圆形 */
        margin-right: 5px; /* 与文本的间隔 */
}


:deep(.van-calendar-day-3){
  color: #f5222d;
  display: inline-block;
        width: 6px; /* 小圆点直径 */
        height: 6px; /* 小圆点直径 */
        background-color: #f5222d; /* 小圆点颜色 */
        border-radius: 50%; /* 圆形 */
        margin-right: 5px; /* 与文本的间隔 */
}



:deep(.van-calendar-day-true) {
    .van-calendar__bottom-info {
        color: #52c41a;
    }
    .van-calendar__bottom-info::before {
        content: '';
        display: inline-block;
        width: 6px; /* 小圆点直径 */
        height: 6px; /* 小圆点直径 */
        background-color: #52c41a; /* 小圆点颜色 */
        border-radius: 50%; /* 圆形 */
        margin-right: 5px; /* 与文本的间隔 */
    }
}

:deep(.van-calendar-day-false) {
    .van-calendar__bottom-info {
        color: #faad14;
    }
    .van-calendar__bottom-info::before {
        content: '';
        display: inline-block;
        width: 6px; /* 小圆点直径 */
        height: 6px; /* 小圆点直径 */
        background-color: #faad14; /* 小圆点颜色 */
        border-radius: 50%; /* 圆形 */
        margin-right: 5px; /* 与文本的间隔 */
    }
}

:deep(.van-field__label) {
  width: 130px;
}
</style>